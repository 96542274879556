<template>
  <div>
    <div class="upload_image">
      <van-image
        width="100"
        class="upload_iamged"
        height="100"
        round
        :src="member_avatar"
      >
        <van-uploader :after-read="afterRead" class="uopload-com" />
      </van-image>
    </div>

    <van-field v-model="username" name="昵称" label="昵称" placeholder="昵称" />

    <van-button round type="danger" class="upload-btn" @click="save"
      >保存</van-button
    >
  </div>
</template>

<script>
import { Field, Uploader, Button, Image, Toast } from "vant";
import { form, post } from "@get/http";
export default {
  components: {
    vanField: Field,
    vanUploader: Uploader,
    vanButton: Button,
    vanImage: Image,
  },
  data() {
    return {
      username: "",
      fileList: {},
      member_avatar: "",
    };
  },
  methods: {
    afterRead(file) {
      console.log(file);
      this.uploads(file.file);
    },
    async save() {
      await post("/Member/edit_information", {
        data: {
          member_birthday: 0,
          member_nickname: this.username,
          member_qq: this.$store.state.user.info.member_qq,
          member_ww: this.$store.state.user.info.member_ww,
        },
      });
      Toast("保存成功");
    },
    async uploads(file) {
      let formData = new FormData();
      formData.append("memberavatar", file);
      let data = await form("/Member/edit_memberavatar", {
        data: formData,
      });
      window.location.reload();
    },
  },
  created() {
    this.member_avatar = this.$store.state.user.info.member_avatar;
    this.username = this.$store.state.user.info.member_nickname;
  },
};
</script>

<style lang="scss" scoped>
.upload_image {
  .upload_iamged {
    margin: 20px auto;
    display: block;
    position: relative;
  }
  .uopload-com {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    ::v-deep {
      .van-uploader__upload {
        width: 100px;
        opacity: 0;
        height: 100px;
      }
    }
  }
}

.upload-btn {
  width: 90%;
  margin: 20px auto;
  display: block;
}
</style>
